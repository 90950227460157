import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ViseuModule } from './viseu/viseu.module';
import { MangualdeModule } from './mangualde/mangualde.module';

import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ViseuModule,
    MangualdeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
