import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'afsantos';
}
