import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

// Modules
import { ViseuRoutingModule } from './viseu-routing.module';

// Components
import { AppComponent } from './app/app.component';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactsComponent } from './contacts/contacts.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AboutComponent,
    ServicesComponent,
    ContactsComponent
  ],
  imports: [
    CommonModule,
    ViseuRoutingModule,
    AngularFontAwesomeModule
  ],
})

export class ViseuModule { }
